import React, { useContext } from "react"
import Container from "../components/Container/Container"
import Seo from "../components/Seo/Seo"
import G from "../images/groenhout_g.png"
import Image from "../images/teamfoto.jpg"
import { useVestigingenQuery } from "../hooks/useVestigingenQuery"
import VestigingContext from "../store/vestiging-context"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { motion } from "framer-motion"
import { Link, graphql, useStaticQuery } from "gatsby"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew"
import CtaButton from "../components/CtaButton/cta"
import Testimonial from "../components/Testimonials/Testimonials"

const AboutPage = () => {
  const ctx = useContext(VestigingContext)

  const data = useVestigingenQuery()
  const vestigingData = data.allWpVestiging.nodes
    .sort((a, b) => a.title.localeCompare(b.title))
    .filter(
      d =>
        d.vestigingen.showVestiging === true &&
        d.vestigingen.showVestiging !== null
    )

  const overOns = useStaticQuery(graphql`
    query AboutPage {
      wpPage(slug: { eq: "over-ons" }) {
        seo {
          metaDesc
          title
        }
        featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
      allWpVerkopendeMakelaar(sort: { fields: date, order: ASC }) {
        edges {
          node {
            title
            content
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            verkopendeMakelaars {
              telefoonnummer
              emailadres
              vestiging
              foto {
                mediaItemUrl
              }
              overonsfoto {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  `)

  const employees = overOns.allWpVerkopendeMakelaar.edges

  const mapMarker = (
    <svg
      width="123"
      height="123"
      viewBox="0 0 123 123"
      fill="none"
      className="mx-auto"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5_382)">
        <path
          d="M61.4998 68.8286C63.5996 68.8286 65.6789 68.415 67.6189 67.6115C69.5589 66.8079 71.3216 65.6301 72.8064 64.1453C74.2912 62.6605 75.469 60.8977 76.2726 58.9577C77.0762 57.0177 77.4898 54.9385 77.4898 52.8386C77.4898 50.7388 77.0762 48.6595 76.2726 46.7195C75.469 44.7795 74.2912 43.0168 72.8064 41.532C71.3216 40.0472 69.5589 38.8694 67.6189 38.0658C65.6789 37.2622 63.5996 36.8486 61.4998 36.8486C57.259 36.8486 53.1918 38.5333 50.1931 41.532C47.1944 44.5307 45.5098 48.5978 45.5098 52.8386C45.5098 57.0794 47.1944 61.1466 50.1931 64.1453C53.1918 67.144 57.259 68.8286 61.4998 68.8286Z"
          stroke="#F8F8F8"
          stroke-width="11.5"
        />
        <path
          d="M18.5527 43.5113C28.649 -0.871238 94.4027 -0.819987 104.448 43.5625C110.341 69.5975 94.1465 91.635 79.9502 105.268C74.9896 110.052 68.3911 117.875 61.4992 117.875C54.6073 117.875 47.9596 110.052 42.999 105.268C28.854 91.635 12.659 69.5463 18.5527 43.5113Z"
          stroke="#F8F8F8"
          stroke-width="11.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_382">
          <rect width="123" height="123" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )

  const image =
    overOns.wpPage.featuredImage !== null
      ? overOns.wpPage.featuredImage.node.mediaItemUrl
      : Image
  const img = Image

  const show = []

  const list = vestigingData.map((v, i) => {
    const emp = employees

    show.push(
      <motion.div
        initial={{ translateY: -100, opacity: 0 }}
        animate={{ translateY: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: i * 0.25 + 0.3 }}
        key={i}
        className={`lg:pr-8 md:pr-4 ${i > 2 ? `` : ``}`}
      >
        <div className="px-8 py-12 bg-blue relative overflow-hidden text-center">
          {mapMarker}
          <h3 className="text-light text-center mb-0 mt-4 uppercase font-black text-2xl">
            {v.title}
          </h3>
          <img
            src={G}
            alt="G-logo"
            className="absolute -bottom-14 m-0 -left-6 h-24"
          />
        </div>
        <div>
          <h3 className="text-blue text-bold mb-2 mt-5 text-base">
            Team {v.title}
          </h3>
          <div className="text-gray flex flex-col text-sm">
            <span>{v.vestigingen.vestigingAdres}</span>
            <span>{v.vestigingen.vestigingPostcode}</span>
          </div>
          <div className="mt-2 flex flex-col text-sm">
            <a
              className="hover:text-red transition-all duration-300"
              href={`tel:${v.vestigingen.vestigingTelefoonnummer}`}
            >
              {v.vestigingen.vestigingTelefoonnummer.startsWith("06")
                ? v.vestigingen.vestigingTelefoonnummer.substr(0, 2) +
                  " - " +
                  v.vestigingen.vestigingTelefoonnummer.substr(2)
                : v.vestigingen.vestigingTelefoonnummer.substr(0, 4) +
                  " - " +
                  v.vestigingen.vestigingTelefoonnummer.substr(4)}
            </a>
            <a
              className="hover:text-red transition-all duration-300"
              href={`mailto:${v.vestigingen.vestigingEmailadres}`}
            >
              {v.vestigingen.vestigingEmailadres}
            </a>
          </div>
        </div>
      </motion.div>
    )

    if (i + 1 == vestigingData.length) {
      emp?.map((e, k) => {
        show.push(
          <motion.div
            initial={{ translateY: -100, opacity: 0 }}
            animate={{ translateY: 0, opacity: 1 }}
            transition={{ duration: 0.3, delay: k * 0.25 + 0.3 }}
            key={k}
            className={`lg:pr-8 md:pr-4 ${k > 2 ? `` : ``}`}
          >
            <div className="px-8 py-12 bg-light relative overflow-hidden text-center h-64">
              <img
                src={e.node.verkopendeMakelaars.overonsfoto?.mediaItemUrl}
                className="w-full h-full absolute top-0 left-0 object-cover"
              ></img>
            </div>
            <div>
              <h3 className="text-blue text-bold mb-1 mt-5 text-base">
                {e.node.title}
              </h3>
              <div className="text-gray flex flex-col text-sm">
                <span
                  dangerouslySetInnerHTML={{ __html: e.node.content }}
                ></span>
              </div>
              <div className="mt-1 flex flex-col text-sm">
                <a
                  className="text-red hover:text-blue transition-all duration-300"
                  href={`tel:${e.node.verkopendeMakelaars.telefoonnummer}`}
                >
                  {e.node.verkopendeMakelaars.telefoonnummer.startsWith("06")
                    ? e.node.verkopendeMakelaars.telefoonnummer.substr(0, 2) +
                      " - " +
                      e.node.verkopendeMakelaars.telefoonnummer.substr(2)
                    : e.node.verkopendeMakelaars.telefoonnummer.substr(0, 4) +
                      " - " +
                      e.node.verkopendeMakelaars.telefoonnummer.substr(4)}
                </a>
                <a
                  href={`mailto:${e.node.title
                    .split(" ")[0]
                    .toLowerCase()}@groenhoutmakelaars.nl`}
                  className="mt-1"
                >
                  {e.node.title.split(" ")[0].toLowerCase()}
                  @groenhoutmakelaars.nl
                </a>
              </div>
            </div>
          </motion.div>
        )
      })
    }
  })

  return (
    <>
      <Seo
        title={overOns.wpPage.seo.title}
        description={overOns.wpPage.seo.metaDesc}
      ></Seo>
      {/* <SmallHero animKey={'about-us'} title="Over ons" overlay={true} overlayImage={G} desc="" image={image}></SmallHero> */}
      <SmallHeroNew
        title="Over ons"
        desc="Meer over Groenhout Makelaars en ons team"
        overlay={true}
        overlayImage={G}
        image={image !== null ? image : Image}
      ></SmallHeroNew>
      {/* <ContentImageLeft styles={`my-16`} image={img} vid={ctx.vestiging !== undefined && ctx.vestiging === 'Beilen' ? `https://player.vimeo.com/video/705758440?h=8857f73acc&autoplay=1&loop=1` : undefined} bg={false} vestiging={ctx.vestiging} title={`Bij Groenhout Makelaars bent u aan het juiste adres!`} sub={`Bij Groenhout Makelaars vindt u een specialist op ieder gebied als het gaat om wonen. Of u nu een huis wilt verkopen, aankopen, een huis wilt laten bouwen of een taxatie nodig bent, met ons team aan specialisten bent u op het juiste adres.`} usp={[]}></ContentImageLeft> */}
      <Container styles={`py-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-1/2 mx-auto">
          <h2 className="text-blue text-4xl">
            Bij Groenhout Makelaars bent u aan het juiste adres!
          </h2>
          <p className="mb-0">
            Bij Groenhout Makelaars vindt u een specialist op ieder gebied als
            het gaat om wonen. Of u nu een huis wilt verkopen, aankopen, een
            huis wilt laten bouwen of een taxatie nodig bent, met ons team aan
            specialisten bent u op het juiste adres.
          </p>
        </div>
      </Container>
      <div className="bg-white lg:px-0 px-8">
        <Container>
          <div>
            <h2 className="text-center text-blue text-3xl mb-0">
              Onze teamleden
            </h2>
          </div>
          <div className="xl:px-24 py-16 grid lg:grid-cols-3 grid-cols-2 gap-8 lg:gap-16">
            {show.map(s => s)}
          </div>
        </Container>
      </div>
      <div className="bg-light lg:py-24 p-8">
        <Container>
          <div className="w-full lg:w-2/3 mx-auto">
            <h2 className="text-3xl lg:w-2/3 mx-auto text-center text-blue">
              Neem nu de{" "}
              <Link
                to="/aanbod"
                className="underline decoration-red underlineRed"
              >
                volgende stap
              </Link>{" "}
              naar uw droomwoning
            </h2>
            <p className="text-center my-8 lg:my-12">
              Alle benodigde stukken worden verzonden naar de notaris. Na de
              eindinspectie van de woning wordt er getekend bij de notaris voor
              de overdracht.
            </p>
            <span className="w-1/2 block mx-auto">
              <CtaButton
                target={"/aanbod"}
                title={"Bekijk ons aanbod"}
                icon={faArrowRight}
              ></CtaButton>
            </span>
          </div>
        </Container>
      </div>
      <Testimonial></Testimonial>
    </>
  )
}

export default AboutPage
